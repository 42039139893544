import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URI,
        prepareHeaders: (headers, api) => {
            headers.set('Content', 'application/json');
            headers.set('Accept', 'application/json');
            headers.set('Authorization', localStorage.getItem("token")
                ? JSON.parse(localStorage.getItem("token"))
                : "");
            return headers;
        }
    }),
    reducerPath: "GymApi",
    tagTypes: [
        "Token",
        "Completed Challenges",
        "Challenge Streak",
        "Workouts Completed",
        "PBs Broke",
        "Group Shout Outs",
        "get Challenge Of Week",
        'group'
    ],
    endpoints: (builder) => ({
        getToken: builder.query({
            query: () => ({
                url: `/oauth/token`,
                method: "POST",
                body: {
                    client_id: process.env.REACT_APP_API_CLIENT_ID,
                    client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
                    grant_type: "client_credentials",
                    scope: "read-users read-workouts read-groups",
                },
            }),
            providesTags: ["Token"],
        }),

        getCompletedChallenges: builder.query({
            query: () => ({
                url: `/api/leaderboards/challenges-completed`
            }),
            providesTags: ["Completed Challenges"],
        }),

        getChallengeStreak: builder.query({
            query: () => ({
                url: `/api/leaderboards/challenge-streak`
            }),
            providesTags: ["Challenge Streak"],
        }),

        getWorkoutsCompleted: builder.query({
            query: () => ({
                url: `/api/leaderboards/workout-sessions-completed`,
            }),
            providesTags: ["Workouts Completed"],
        }),

        getPBsBroke: builder.query({
            query: () => ({
                url: `/api/leaderboards/rep-pbs-broken`,
            }),
            providesTags: ["PBs Broke"],
        }),

        getGroupShoutOuts: builder.query({
            query: (groupId) => ({
                url: `/api/group/${groupId}/shout-outs`,
                method: "GET"
            }),
            providesTags: ["Group Shout Outs"],
        }),
        getChallengeOfWeek: builder.query({
            query: () => ({
                url: `/api/group-challenge/featured`,
                method: "GET"
            }),
            providesTags: ["get Challenge Of Week"],
        }),
        getGroup: builder.query({
            query: (id) => ({
                url: `/api/group/${id}`,
                method: "GET"
            }),
            providesTags: ["group"],
        }),
    }),
});

export const {
    useGetTokenQuery,
    useGetCompletedChallengesQuery,
    useGetChallengeStreakQuery,
    useGetWorkoutsCompletedQuery,
    useGetPBsBrokeQuery,
    useGetGroupShoutOutsQuery,
    useGetChallengeOfWeekQuery,
    useGetGroupQuery
} = apiSlice;
