import React, {useEffect} from "react";

// api
import {useGetTokenQuery} from "../store/apiSlice";
import MissingGroup from "./MissingGroup";
import Layout from "./Layout";
import Loading from "../utils/Loading";
import Screens from "./Screens";


const Main = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const groupId = urlParams.get('group');
    const {data: tokenData, isLoading: tokenLoading} = useGetTokenQuery();

    useEffect(() => {
        // Get Token
        if (!localStorage.getItem("token") && !tokenLoading) {
            localStorage.setItem("token", JSON.stringify(tokenData.access_token));
        }
    }, [tokenData, tokenLoading, groupId]);
    if (tokenLoading) {
        return <Layout>
            <Loading/>
        </Layout>
    }

    if (!groupId) {
        return <Layout><MissingGroup/></Layout>;
    }

    return <Layout>
        <Screens groupId={groupId}/>
    </Layout>;
};

export default Main;
