import {useGetChallengeStreakQuery} from "../../store/apiSlice";
import Leaderboard from "./Leaderboard";

const ChallengeStreak = () => {
    const {data, isLoading} = useGetChallengeStreakQuery();
    let text = data?.data[0].result.value === 1 ? "Challenge in a row!" : "Challenges in a row!";
    return (
        <Leaderboard
            data={data}
            isLoading={isLoading}
            text={text}
            active={"challenge_streak"}
        />
    );
};

export default ChallengeStreak;
