import React from "react";

const MissingGroup = () => {
    return (
        <section className="flex justify-center gap-48 px-10 items-center h-4/5 text-white items-center min-h-screen">
            The site must be opened with a Group ID provided in the URL, such as:
            <br/>
            {window.location.origin}?group=1234
        </section>
    );
};

export default MissingGroup;
