import {HiTrophy} from "react-icons/hi2";
import React from "react";
import PropTypes from "prop-types";

const ShoutOut = (props) => {
    let partCount = 0;
    let reason = props.reason.split(" ").map(part => {
        if (part === "%s") {
            if (props.reasonParams.length < partCount) {
                return <>{part}{" "}</>;
            }
            part = <><span className={"text-green-300"}>{props.reasonParams[partCount]}</span>{" "}</>;
            partCount++;
        } else {
            part = <>{part}{" "}</>;
        }
        return part;
    });

    return <div className="flex items-center h-full justify-between px-48 -mt-16 w-100">
        <div className="font-semibold leading-normal text-white w-2/3">
            <div className={"-mt-36"}>
                <p className={"text-6xl whitespace-nowrap"}>Shout out to</p>
                <div className="text-8xl text-green-300 my-7 whitespace-break-spaces break-words overflow-hidden max-h-72 py-3">
                    {props.name}
                </div>
                <p className="text-6xl">{reason.map((part, key) => <span key={key}>{part}</span>)}</p>
            </div>
        </div>
        <div className="relative w-1/3">
            <div className="text-gray-800 top-[15%] w-[45%] h-[25%] mx-auto font-bold text-4xl text-center absolute flex justify-center" style={{left: 0, right: 0}}>
                <p className={'my-auto'}>{props.trophyText}</p>
            </div>
            <HiTrophy className="h-full w-full text-[#FFC839]"/>
        </div>
    </div>
}

ShoutOut.propTypes = {
    name: PropTypes.string,
    reason: PropTypes.string,
    reasonParams: PropTypes.array,
    trophyText: PropTypes.string
}

export default ShoutOut;
