import React, {useEffect} from "react";
import * as PropTypes from "prop-types";
import {useGetGroupQuery, useGetTokenQuery} from "../store/apiSlice";

function HeaderItem(props) {
    let activeClasses = "border-[#FD2B12] text-[#C1FF9B] font-bold";
    let inactiveClasses = "border-zinc-500 font-semibold";
    let classes = props.active ? activeClasses : inactiveClasses;
    return <span className={`border-b px-8 ${classes} flex flex-col justify-center`} style={{borderBottomWidth: 4}}>{props.text}</span>;
}

HeaderItem.propTypes = {
    active: PropTypes.bool,
    text: PropTypes.string
};

function GroupLogo(props) {
    let edits = {
        bucket: process.env.REACT_APP_AWS_IMAGE_BUCKET,
        key: props.url,
        edits: {
            resize: {
                width: 310,
                height: 100,
                fit: "inside"
            }
        }
    };
    let qs = btoa(JSON.stringify(edits));
    let base = process.env.REACT_APP_AWS_IMAGE_ENDPOINT;
    let groupLogo = `${base}/${qs}`;
    return <div className={"h-[100px] w-[310px] relative"}>
        {props.url &&
            <img className={'max-h-full'} src={groupLogo} alt={props.alt}/>
        }
        {!props.url && props.name &&
            <h1 className={"text-5xl text-white"}>{props.name}</h1>
        }
    </div>;
}

GroupLogo.propTypes = {
    url: PropTypes.string,
    name: PropTypes.string
};
const Header = ({active}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const groupId = urlParams.get('group');
    const {data, isLoading} = useGetGroupQuery(groupId);
    const {data: tokenData, isLoading: tokenLoading} = useGetTokenQuery();

    useEffect(() => {
        // Get Token
        if (!localStorage.getItem("token") && !tokenLoading) {
            // console.log(tokenData);
            localStorage.setItem("token", JSON.stringify(tokenData.access_token));
        }
    }, [tokenData, tokenLoading]);
    if (tokenLoading) {
        return;
    }
    if (isLoading) {
        return;
    }

    let items = {
        leaderboards: "Leaderboards",
        shoutouts: "Shout Outs",
        download: "Download",
        community: "Community"
    };
    return (
        <div className="flex">
            <div className="w-1/3">
                <GroupLogo name={data?.display_name} url={data?.logo_for_dark_background_url}/>
            </div>
            <div className="text-[#7A7A7A] flex text-[32px] w-2/3 justify-end">
                <div className={'flex h-20'}>
                    {Object.getOwnPropertyNames(items).map((item, key) => <HeaderItem active={active === item} text={items[item]} key={key}/>)}
                </div>
            </div>
        </div>
    );
};

export default Header;
