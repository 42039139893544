import {useGetGroupShoutOutsQuery} from "../store/apiSlice";
import React, {useEffect, useState} from "react";
import CompletedChallenges from "./leaderBoards/CompletedChallenges";
import ChallengeStreak from "./leaderBoards/ChallengeStreak";
import WorkoutsCompleted from "./leaderBoards/WorkoutsCompleted";
import PBsBroke from "./leaderBoards/PBsBroke";
import ShoutOut from "./shoutouts/ShoutOut";
import DownloadBrawn from "./DownloadBrawn";
import Events from "./community/Events";
import Header from "../utils/Header";
import {useDispatch} from "react-redux";

const Screens = (props) => {
    const [currentComponent, setCurrentComponent] = useState({component: <CompletedChallenges/>, active: "leaderboards"});
    const {data, isLoading, refetch} = useGetGroupShoutOutsQuery(props.groupId);
    const dispatch = useDispatch();
    useEffect(() => {

        let components = [
            {component: <CompletedChallenges/>, active: "leaderboards"},
            {component: <ChallengeStreak/>, active: "leaderboards"},
            {component: <WorkoutsCompleted/>, active: "leaderboards"},
            {component: <PBsBroke/>, active: "leaderboards"}
        ];

        if (!isLoading) {
            data.data.forEach((item) => {
                if (item.reason === "shout_out_milestone") {
                    const {shout_out_milestone, user} = item;
                    let name = user.first_name + " " + user.last_name;
                    let value = shout_out_milestone.target.toLocaleString();
                    let trophyText = `${value} ${shout_out_milestone.attribute} completed!`;
                    let reason = `For completing %s ${shout_out_milestone.attribute}!`;
                    if (shout_out_milestone.attribute === 'volume') {
                        value += "kg";
                        trophyText = `${value} ${shout_out_milestone.attribute} lifted!`
                        reason = `For lifting %s ${shout_out_milestone.attribute}!`;
                    }
                    let component = <ShoutOut name={name} trophyText={trophyText} reason={reason} reasonParams={[value]}/>;
                    components.push({component, active: "shoutouts"});
                } else if (item.reason === "new_challenge_streak") {
                    const {user} = item;
                    let challenges = item.data[0].value;
                    let name = user.first_name + " " + user.last_name;
                    let trophyText = `${challenges.toLocaleString()} challenges completed!`;
                    let reason = `For hitting their highest session streak!`;
                    let component = <ShoutOut name={name} trophyText={trophyText} reason={reason} reasonParams={[]}/>;
                    components.push({component, active: "shoutouts"});
                }
            });
            components.push({component: <DownloadBrawn/>, active: "download"});
            components.push({component: <Events/>, active: "community"});

            // CHANGE COMPONENT AFTER EACH 12 SECONDS
            let index = 0;

            const interval = setInterval(() => {
                if (index === (components.length - 1)) {
                    refetch();
                }
                setCurrentComponent(components[index]);
                index = (index + 1) % components.length;
            }, 12000);
            return () => clearInterval(interval);
        }
    }, [isLoading, data, props.groupId, dispatch, refetch]);

    return <>
        <div className="p-5">
            <Header active={currentComponent.active}/>
        </div>
        <div className="relative font-semibold h-screen">
            {currentComponent.component}
        </div>
    </>;
}

export default Screens;
