import {useGetCompletedChallengesQuery,} from "../../store/apiSlice";
import Leaderboard from "./Leaderboard";

const CompletedChallenges = () => {
    const {data, isLoading} = useGetCompletedChallengesQuery();
    let text = +data?.data[0].result.value === 1 ? "Challenge completed!" : "Challenges completed!";
    return (
        <Leaderboard
            data={data}
            isLoading={isLoading}
            text={text}
            active={"challenges_completed"}
        />
    );
};
export default CompletedChallenges;
