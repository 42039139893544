import React from "react";

const DownloadBrawn = () => {
    return (
        <div className="flex justify-center gap-48 px-10 items-center h-4/5 text-white">
            <div className=" pl-20">
                <img src="./assets/images/QR.png" alt="" className="w-[21rem]"/>
            </div>
            <div className="w-[42%] text-3xl">
                <h1 className="text-6xl mb-5 leading-tight">
                    Time to <span className="text-[#C2FAA5]">challenge yourself</span>{" "}
                    with brawn
                </h1>
                <p className="mb-10 leading-[3rem]">Build your strength, your progress, and compete <br/>
                    with others in your gym through workout challenges.</p>
                <p className="leading-[3rem]">With{" "}<span className="text-[#C2FAA5]">leaderboards, challenge streaks</span>, and <span
                    className="text-[#C2FAA5]">personal scores</span>, we're here to motivate you to push yourself in new ways.</p>
            </div>
        </div>
    );
};
DownloadBrawn.active = "download";

export default DownloadBrawn;
