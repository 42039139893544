const Events = () => {
    return (
        <div className="flex justify-center gap-36 px-10 items-center h-4/5 text-white">
            <div className="w-[47%] text-3xl tracking-wider">
                <h1 className="text-6xl font-bold mb-5">
                    <span className="text-[#C2FAA5]">Connect</span> with others
                </h1>
                <p className="mb-8 leading-[3rem]"> Did someone say <span className="text-green-300">leaderboard</span>? Take on a challenge created by PTs to test yourself, see your score, and how it stacks up against those around you. </p>
                <p className="leading-[3rem]"> Your gym is building a community of those looking to build their strength with Brawn, so get involved, encourage each other{" "} <span className="text-[#C2FAA5]">push harder</span> and take on new challenges. </p>
            </div>
            <div className="pl-20">
                <img src="./assets/images/QR.png" alt="" className="w-[21rem]"/>
            </div>
        </div>
    );
};

export default Events;
