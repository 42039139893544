import React from "react";
import Loading from "../../utils/Loading";
import {HiTrophy} from "react-icons/hi2";
import {motion} from "framer-motion";
import * as PropTypes from "prop-types";
import createImageUrl from "../../utils/Image";

function ShinyUserAvatar(props) {
    if (!props.avatarUrl) {
        return <span
            className=" absolute -top-40 rounded-full shadow-[0px_0px_42.95918273925781px_17.183673858642578px_#FFC839] w-[373.74px] h-[373.74px] flex text-9xl justify-center items-center uppercase bg-gray-600">
        {props.firstName.charAt(0)}{props.lastName.charAt(0)}</span>
    }
    return <img
        src={createImageUrl(props.avatarUrl, 374, 374)}
        className="absolute -top-40 rounded-full shadow-[0px_0px_42.95918273925781px_17.183673858642578px_#FFC839] object-cover w-[374px] h-[374px]"
        alt="avatar"
    />;
}

ShinyUserAvatar.propTypes = {
    avatarUrl: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string
};

function FirstPlace(props) {
    return <div className="relative flex justify-center bg-[#BDC2FA] w-[479px] h-[706px] rounded-t-3xl mt-40">
        <ShinyUserAvatar avatarUrl={props.avatarUrl} firstName={props.firstName} lastName={props.lastName}/>

        <div className="flex flex-col items-center mt-64">
            <div className="relative">
                <p className="text-black top-3 font-bold text-2xl text-center absolute" style={{left: 0, right: 0}}>
                    1st
                </p>
                <HiTrophy className="text-[111px] mx-auto text-yellow-400"/>
            </div>
            <h2 className="text-black text-[3rem] font-bold overflow-hidden w-[100%] mt-7 text-center">
                {`${props.firstName} ${props.lastName}`.length > 20 ? (
                    <motion.div
                        animate={{x: ["0%", "-100%", "0%"]}}
                        transition={{
                            ease: "linear",
                            delay: 2,
                            duration: 7,
                            // repeat: Infinity,
                            // repeatDelay: 2,
                        }}
                        className="flex gap-1 whitespace-nowrap w-72"
                    >
                        {props.firstName} {props.lastName}
                    </motion.div>
                ) : (
                    <span className="whitespace-nowrap">
                          {props.firstName} {props.lastName}
                        </span>
                )}
            </h2>
            <p className="text-black text-[32px] mt-2 font-bold">
                {props.result.value.toLocaleString()} {props.text}
            </p>
        </div>
    </div>;
}

FirstPlace.propTypes = {
    avatarUrl: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    result: PropTypes.any,
    text: PropTypes.string
};

function LeaderboardPill(props) {
    let classes = props.active ? "bg-[#C2FAA5]" : "bg-[#504B68]";
    return <span className={`px-3 py-3 ${classes} rounded-lg font-bold`}>{props.title}</span>;
}

LeaderboardPill.propTypes = {
    active: PropTypes.bool,
    title: PropTypes.any
};

function LeaderboardPills(props) {
    let items = {
        challenges_completed: "Challenges Completed",
        challenge_streak: "Challenge Streak",
        workouts_completed: "Workouts Completed",
        pbs_Broken: "PB's Broken"
    };
    return <div className="mx-5 mt-5 flex gap-5 text-3xl font-semibold">
        {Object.getOwnPropertyNames(items).map((prop, key) => <LeaderboardPill active={props.active === prop} title={items[prop]} key={key}/>)}
    </div>;
}

LeaderboardPills.propTypes = {active: PropTypes.any};

function UserAvatar(props) {
    if (props.avatarUrl) {
        let path = createImageUrl(props.avatarUrl, 64, 64);
        return <img src={path} alt="" className="w-16 h-16 rounded-full object-cover"/>;
    }
    return <span className="flex text-3xl justify-center items-center rounded-full uppercase bg-gray-600 w-16 h-16">
        {props.firstName.charAt(0)}{props.lastName.charAt(0)}</span>;
}

UserAvatar.propTypes = {
    avatarUrl: PropTypes.any,
    firstName: PropTypes.any,
    lastName: PropTypes.any
};
const Leaderboard = ({data, isLoading, text, active}) => {
    if (isLoading) {
        return <>
            <LeaderboardPills active={active}/>
            <Loading/>
        </>
    }
    return (
        <>
            <div className={'mb-6'}>
                <LeaderboardPills active={active}/>
            </div>
            <div className="flex gap-28 text-white h-7 pl-32 mt-10">
                <FirstPlace avatarUrl={data?.data[0].avatar_url} firstName={data?.data[0].first_name} lastName={data?.data[0].last_name} result={data?.data[0].result}
                            text={text}/>
                <div className="flex flex-col gap-7 w-[65%]">
                    {data?.data.slice(1).map(
                        ({id, first_name, last_name, rank, avatar_url, result}, index) =>
                            (
                                <div key={index} className="flex justify-between border-b pb-4 text-3xl">
                                    <div className="flex gap-5 items-center">
                                        <div>{rank}</div>
                                        <div className="flex justify-center items-center ">
                                            <UserAvatar avatarUrl={avatar_url} firstName={first_name} lastName={last_name}/>
                                        </div>
                                        <div className="w-[50rem] overflow-hidden">
                                            {`${first_name} ${last_name}`.length > 30 ? (
                                                <motion.div
                                                    animate={{x: ["0rem", "-18rem", "0rem"]}}
                                                    transition={{
                                                        ease: "linear",
                                                        delay: 2,
                                                        duration: 7,
                                                        // repeat: Infinity,
                                                        // repeatDelay: 1,
                                                    }}
                                                    className="flex gap-1 whitespace-nowrap"
                                                >
                                                    {first_name} {last_name}
                                                </motion.div>
                                            ) : (
                                                <span className="whitespace-nowrap">{first_name} {last_name}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="text-white flex items-center">
                                        {result.value.toLocaleString()}
                                    </div>
                                </div>
                            )
                    )}
                </div>
            </div>
        </>
    );
};
export default Leaderboard;
