import {useGetPBsBrokeQuery} from "../../store/apiSlice";
import Leaderboard from "./Leaderboard";

const PBsBroke = () => {
    const {data, isLoading} = useGetPBsBrokeQuery();
    let text = +data?.data[0].result.value === 1 ? "PB Broken!" : "PB's Broken!";
    return (
        <Leaderboard
            data={data}
            isLoading={isLoading}
            text={text}
            active={"pbs_Broken"}
        />
    );
};
export default PBsBroke;
