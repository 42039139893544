import {useGetWorkoutsCompletedQuery} from "../../store/apiSlice";
import Leaderboard from "./Leaderboard";

const WorkoutsCompleted = () => {
    const {data, isLoading} = useGetWorkoutsCompletedQuery();
    let text = +data?.data[0].result.value === 1 ? "Workout completed!" : "Workouts completed!";
    return (
        <Leaderboard
            data={data}
            isLoading={isLoading}
            text={text}
            active={"workouts_completed"}
        />
    );
};
export default WorkoutsCompleted;
